import { node, string } from 'prop-types';
import classnames from 'classnames';

import Icon from '../icon';

const className = classnames(
  'flex',
  'flex-row',
  'justify-center',
  'items-center',
  'place-items-center',
  'cursor-pointer',
  'py-1.5',
  'px-3'
);

const CardTextIcon = ({ children, icon }) => (
  <div className={className}>
    <div className="flex items-center pr-2">
      <Icon icon={icon} type="light" size={'lg'} />
    </div>
    <div>{children}</div>
  </div>
);

CardTextIcon.propTypes = {
  children: node.isRequired,
  icon: string.isRequired,
};

export default CardTextIcon;
