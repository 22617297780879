import { node, string } from 'prop-types';
import Link from 'next/link';
import classnames from 'classnames';

import { CardTextIcon } from '@personly/libs-widgets';

const className = classnames(
  'flex',
  'flex-row',
  'flex-1',
  'p-0.5',
  'rounded',
  'bg-background-color',
  'text-card-link-color',
  'justify-center'
);

const CardLink = ({ children, href, icon }) => (
  <div className={className}>
    <Link className="text-card-link-color" prefetch={false} href={href}>
      <CardTextIcon icon={icon}>{children}</CardTextIcon>
    </Link>
  </div>
);

CardLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
  icon: string.isRequired,
};

export default CardLink;
