'use client';

import { node, object } from 'prop-types';
import Link from 'next/link';
import { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';

import { useLocalizedPathname } from '@personly/libs-hooks';

const RegisterInline = ({ children, languages }) => {
  const searchParams = useSearchParams();

  const localizedPathname = useLocalizedPathname(languages);

  const href = useMemo(
    () => ({
      pathname: localizedPathname('/register'),
      query: searchParams.toString(),
    }),
    [localizedPathname, searchParams]
  );

  return (
    <Link
      className="text-card-link-color"
      key="register"
      prefetch={false}
      href={href}
    >
      {children}
    </Link>
  );
};

RegisterInline.propTypes = {
  children: node.isRequired,
  languages: object.isRequired,
};

export default RegisterInline;
