'use client';

import { node } from 'prop-types';

import { Button } from '@personly/libs-widgets';

import { useLogin } from '../../providers';

const LoginSubmitButton = ({ children }) => {
  const { isDisabled } = useLogin();

  return (
    <Button block type="submit" disabled={isDisabled}>
      {children}
    </Button>
  );
};

LoginSubmitButton.propTypes = { children: node.isRequired };

export default LoginSubmitButton;
