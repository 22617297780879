'use client';

import { useEffect, useMemo } from 'react';
import { string } from 'prop-types';
import { useSearchParams } from 'next/navigation';

import { useClients } from '../../hooks';

const ClientName = ({ organizationName }) => {
  const searchParams = useSearchParams();

  const { client, getClient } = useClients();

  const clientId = useMemo(() => {
    const id = searchParams.get('client_id');
    return id || process.env.NEXT_PUBLIC_AUTH_PROVIDER_MY;
  }, [searchParams]);

  useEffect(() => {
    if (!clientId) return;

    getClient(clientId);
  }, [getClient, clientId]);

  return <strong>{client.name || organizationName}</strong>;
};

ClientName.propTypes = { organizationName: string.isRequired };

export default ClientName;
