'use client';

import { useEffect, useRef } from 'react';
import { string } from 'prop-types';

import { Input } from '@personly/libs-widgets';

import { useLogin } from '../../providers';

const LoginEmailInput = ({ id, placeholder }) => {
  const { email, setEmail } = useLogin();
  const usernameRef = useRef();

  useEffect(() => {
    if (usernameRef) usernameRef.current.focus();
  }, []);

  return (
    <Input
      ref={usernameRef}
      type="email"
      placeholder={placeholder}
      name="username"
      id={id}
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  );
};

LoginEmailInput.propTypes = {
  id: string.isRequired,
  placeholder: string.isRequired,
};

export default LoginEmailInput;
