'use client';

import { node, object, string } from 'prop-types';
import { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';

import { useLocalizedPathname } from '@personly/libs-hooks';

import CardLink from '../card-link';

const CardOAuthLink = ({ children, icon, languages, pathname }) => {
  const searchParams = useSearchParams();
  const localizedPathname = useLocalizedPathname(languages);

  const cleanedParams = useMemo(() => {
    const cleanedSearchParams = new URLSearchParams(searchParams);
    cleanedSearchParams.delete('code');
    cleanedSearchParams.delete('state');
    return cleanedSearchParams;
  }, [searchParams]);

  const href = useMemo(
    () => ({
      pathname: localizedPathname(pathname),
      query: cleanedParams.toString(),
    }),
    [localizedPathname, cleanedParams, pathname]
  );

  return (
    <CardLink icon={icon} href={href}>
      {children}
    </CardLink>
  );
};

CardOAuthLink.propTypes = {
  children: node.isRequired,
  icon: string.isRequired,
  languages: object.isRequired,
  pathname: string.isRequired,
};

export default CardOAuthLink;
